var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      attrs: { "no-gutters": "", "align-content": "center", justify: "center" }
    },
    [
      _vm._.isEmpty(_vm.userSupervisors)
        ? _c("h4", { staticClass: "mb-7" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("messages.emptyState", {
                    entity: _vm.$tc("models.supervisor.entity")
                  })
                ) +
                " "
            )
          ])
        : _vm._l(_vm.userSupervisors, function(supervisor, index) {
            return _c("v-hover", {
              key: index,
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var hover = ref.hover
                      return [
                        _c(
                          "v-card",
                          {
                            staticClass: "mx-5 my-5",
                            attrs: {
                              width: "300",
                              height: hover ? "auto" : 325,
                              elevation: hover ? 12 : 0
                            },
                            on: {
                              click: function($event) {
                                return _vm.$router.push({
                                  name: "UserPublicProfile",
                                  params: { uuid: supervisor.id }
                                })
                              }
                            }
                          },
                          [
                            _c("v-img", {
                              staticClass: "transition-ease-in-out",
                              attrs: {
                                src: _vm.getFileUrl(supervisor.avatar),
                                height: hover ? 300 : 250
                              },
                              on: {
                                error: function($event) {
                                  _vm.hasError = true
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "placeholder",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass:
                                              "primary fill-height ma-0",
                                            attrs: {
                                              "no-gutters": "",
                                              align: "center",
                                              justify: "center"
                                            }
                                          },
                                          [
                                            _vm.hasError ||
                                            _vm._.isEmpty(supervisor.avatar)
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "name-initials-size white--text headline"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          supervisor.initials
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "headline" },
                                      [_vm._v(_vm._s(supervisor.name))]
                                    ),
                                    _c("v-list-item-subtitle", [
                                      _vm._v(_vm._s(supervisor.email))
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            hover
                              ? _c(
                                  "v-list",
                                  { key: "list" },
                                  [
                                    _c(
                                      "v-list-item",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "py-0",
                                                attrs: { cols: "6" }
                                              },
                                              [
                                                _c("v-subheader", {
                                                  staticClass:
                                                    "fill-height overline m-0 p-0",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "models.user.attributes.jobTitle"
                                                      )
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "py-0",
                                                attrs: { cols: "6" }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(supervisor.jobTitle)
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "py-0",
                                                attrs: { cols: "6" }
                                              },
                                              [
                                                _c("v-subheader", {
                                                  staticClass:
                                                    "fill-height overline m-0 p-0",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "models.user.attributes.phoneNumber"
                                                      )
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "py-0",
                                                attrs: { cols: "6" }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(supervisor.phoneNumber)
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-subheader", {
                                      staticClass: "fill-height overline mt-4",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("layout.userMenu.permissions")
                                        )
                                      }
                                    }),
                                    _vm._l(
                                      supervisor.supervisingPermissions,
                                      function(permission, index) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: index,
                                            staticClass: "mx-3 text-xs"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("uppercase")(
                                                    _vm.$t(
                                                      "availablePermissions." +
                                                        _vm
                                                          .availablePermissions[
                                                          permission.sym
                                                        ] +
                                                        ".title"
                                                    )
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }